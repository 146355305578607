html {
  font-family: sans-serif; // 1
  text-size-adjust: 100%; // 2
  -webkit-text-size-adjust: 100%; // 2
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: $root-font-size !important;
  font-weight: $font-weight-base;
  font-family: $font-family-sans-serif;

  // Tablet mode
  @include media-breakpoint-down(lg) {
    font-size: $root-font-size-lg !important;
  }

  // Mobile mode
  @include media-breakpoint-down(md) {
    font-size: $root-font-size-md !important;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a:hover,
  a:active,
  a:focus {
    text-decoration: none !important;
  }
}

body {
  display: flex;
  flex-direction: column;
  color: $body-color;
}

// Angular integration
router-outlet {
  display: none;
}

canvas {
  user-select: none;
}

.dropzone {
  min-height: auto;
  padding: 1.5rem 1.75rem;
  text-align: center;
  cursor: pointer;
  border: 1px dashed #04c8c8;
  background-color: #dcfdfd;
  border-radius: 0.475rem !important;
}

.right-0 {
  right: 0;
}

.top-4 {
  top: 4rem;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.rounded-top-right-0 {
  border-top-right-radius: 0;
}

.rounded-bottom-right-0 {
  border-bottom-right-radius: 0;
}

.scroll-container {
  overflow-x: auto;
}

.indiana-scroll-container--hide-scrollbars {
  overflow: auto !important;
  overflow-y: hidden;
}

.scroll-container.indiana-scroll-container--dragging {
  cursor: grabbing;
}

.rs-picker-date-menu {
  z-index: 1000;
}

.line-clamp-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 40px;
}

.tickets-column {
  max-height: 60vh;
  overflow-y: auto;
}

.tickets-column-length {
  border: 1px solid lightgray;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: gray;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
} 

.accordion-button {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.h-200 {
  height: 200px;
  max-height: 200px;
}

.card-category-action {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.2;
  color: #544d61;

  i {
    font-size: 10px;
    color: #544d61;
  }
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 10000;
}

.rs-picker-date-menu {
  z-index: 100000;
}

.select-option--mw350 {
  max-width: 350px;
}

.filter-options {
  box-shadow: 0px 0 5px 0px rgb(0 0 0 / 15%);
}

.options-wrapper {
  max-height: 320px;
  overflow: auto;
}

.badge-light-primary {
  background-color: #f1faff;
}

.filters-form select {
  appearance: auto !important;
}

.bg-secondary-light {
  background: #f5f8fa;
}

.w-200 {
  width: 200px;
}

.w-300 {
  width: 300px;
}

.exceeded-deadline i {
  font-size: 18px;
  color: #e91e63;
}

.right-m-15 {
  right: -15px;
}