//
// Main
//

// Body
body {
  background-color: $page-bg;
}

// Font color from Content background color
.text-page-bg {
  color: $page-bg;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
  // Containers
  .container,
  .container-xxl,
  .container-fluid {
    padding: 0 get($content-spacing, desktop);
  }

  // Wrapper
  .wrapper {
    transition: padding-left get($aside-config, transition-speed) ease,
      margin-right get($aside-config, transition-speed) ease;

    // Aside default enabled and aside fixed modes
    .aside-enabled.aside-fixed & {
      transition: padding-left get($aside-config, transition-speed) ease;
      padding-left: get($aside-config, width);
    }

    // Aside default enabled, aside fixed and aside minimize modes
    .aside-enabled.aside-fixed[data-kt-aside-minimize='on'] & {
      transition: padding-left get($aside-config, transition-speed) ease;
      padding-left: get($aside-config, minimized-width);
    }

    // Fixed header mode
    .header-fixed & {
      padding-top: get($header-config, fixed, height, desktop);
    }

    // Fixed header & fixed toolbar modes
    .header-fixed.toolbar-fixed & {
      // padding-top: calc(#{get($header-config, fixed, height, desktop)} + var(--kt-toolbar-height));
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  // Containers
  .container,
  .container-xxl,
  .container-fluid {
    max-width: none;
    padding: 0 get($content-spacing, tablet-and-mobile);
  }

  // Wrapper
  .wrapper {
    // Fixed header mode
    .header-tablet-and-mobile-fixed & {
      padding-top: get($header-config, fixed, height, tablet-and-mobile);
    }

    // Fixed header & fixed toolbar modes
    .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed & {
      padding-top: calc( var(--kt-toolbar-height-tablet-and-mobile));
    }
  }
}

table {
  width: 100%;
  text-align: center;
  background-color: white;
  border-radius: 0.375rem;
  --tw-shadow-color: #000;
}

table thead,
table tbody tr {
  border-bottom-width: 1px;
  height: 4rem; 
  border-color: #e5e7eb;
}

table thead tr {
  text-align: center;
}

.permissions-table {
  background-color: white;
}

.permissions-table tbody tr:nth-child(even) {
  background-color: #F4F4F4;
  border-bottom: 0;
  height: 3.5rem;
}

tbody tr:last-child {
  border: 0;
} 

.permissions-table tbody tr td:not(:first-child),
.permissions-table thead tr th:not(:first-child) {
  text-align: left;
  padding-left: 2.5rem; /* 40px */
  padding-right: 2.5rem; /* 40px */
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

table thead tr th,
table tbody tr td {
  display: table-cell;
  padding-left: 0.75rem; /* 12px */
  min-width: fit-content;
  max-width: 14rem;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
  line-height: 1.25rem; /* 20px */
  font-weight: normal;
}

table thead tr th {
  color: #979797;
}

table tbody tr td, 
table tbody tr td a {
  color: #333;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.loader {
  margin: auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.1em solid rgba(255, 255, 255, 0.2);
  border-right: 0.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.1em solid rgba(255, 255, 255, 0.2);
  border-left: 0.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 0.5s infinite linear;
  animation: load8 0.5s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.custom-button {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.25rem;
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid #eee;
  text-align: center;
  margin-top: 3rem;
  padding: 1rem 1.3rem;
  color: white;

  &:disabled {
    cursor: not-allowed;
    background-color: rgb(148 163 184/var(--tw-bg-opacity));
  }
}

.bg-white {
  background-color: white;
  color: black;
}

.color-primary {
  color: white !important
}

.bg-primary {
  background-color: #1DA865 !important;
}

// <Input />
.input-container {
  width: auto;
  position: relative;
}

.input-relative {
  position: relative;
  min-height: 44px;
  height: 100%;
}

.label-block {
  display: block;
  margin-bottom: 5px;
}

.input-inner {
  border: 1px solid #DBDDE0;
  background-color: transparent;
  width: 100%;
  min-width: 18px;
  min-height: 44px;
  border-width: 1px;
  border-radius: 6px;
  font-size: 14px;
  padding: 10px;

  &:focus {
      outline: none;
      color: rgb(17 24 39);
      border: 1px solid black;
  }

  &:hover {
      border: 1px solid black;
  }

  &.border-red {
      border: 1px solid red;
  }

  &.pl-14 {
      padding-left: 56px;
  }

  &.pr-10 {
      padding-right: 40px;
  }
}

.input-icon__overlay {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0px;
  bottom: 0px;
}

.input-icon__overlay--left {
  padding-left: 8px;
  left: 2;
  right: unset;

  [dir=rtl] & {
    padding-right: 8px;
    right: 2;
    left: unset
  }
}

.input-icon__overlay--right {
  padding-right: 8px;
  right: 2;
  left: unset;

  [dir=rtl] & {
    padding-left: 8px;
    right: unset;
    left: 2;
  }
}

.input-icon_absolute {
  position: absolute;
}

.input-icon_absolute--right {
  right: .5rem;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
  z-index: 1;

  [dir=rtl] & {
    left: .5rem;
    right: unset;
    padding-left: 0.5rem;
  }
}

.input-icon_absolute--left {
  right: unset;
  left: 0.5rem;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;

  [dir=rtl] & {
    right: 0.5rem;
    left: unset;
    padding-right: 0.5rem;
  }
}

fieldset {
  font-size: 14px;
  border-radius: 4px;
  text-align: left;
  position: absolute;
  inset: -5px 0px 0px;
  margin: 0px;
  padding: 0px 8px;
  pointer-events: none;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border: 1px solid #e5e7eb;

  [dir=rtl] & {
    text-align: right;
    inset: -5px 0px 0px;

  }
}

.input-relative:hover fieldset,
.input-relative:focus fieldset {
  border: 1px solid #000;
}

legend {
  float: unset;
  overflow: hidden;
  display: block;
  width: auto;
  margin: 0;
  padding: 0;
  height: 11px;
  font-size: 12px;
  visibility: hidden;
  max-width: 0.01px;
  -webkit-transition: max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  white-space: nowrap;
}

fieldset.border-red {
  border: 1px solid red;
}

legend span {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
  visibility: visible;
  opacity: 0;
}

.legend--focused {
  max-width: 100%;
}

.input-field[type="date"],
.input-field[type="datetime-local"],
.input-field[type="time"] {
  color: transparent;
  padding-left: 10px;
  padding-right: 10px;
}

[dir=rtl] input[type="date"]::-webkit-calendar-picker-indicator { position: absolute;  }
[dir=rtl] input[type="date"]::-webkit-datetime-edit { position: relative; text-align: right; }
[dir=rtl]  input[type="date"]::-webkit-datetime-edit-fields-wrapper { position: relative; left: 250px; }
.select-overlay .select__control {
  border: none !important; 
  outline: none !important;
  background-color: transparent;
}

.select-overlay .select__control--is-focused {
  border: none !important;
  border-color: transparent !important;
  box-shadow: none;
}

.input-label {
  font-size: 14px !important;
  margin-bottom: 0 !important;
  font-weight: normal !important;
  color: #898A8D !important;
  letter-spacing: 0.00938em;
  padding: 0px;
  display: block;
  transform-origin: left top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 24px);
  position: absolute;
  left: 0px;
  right: unset;
  top: -5px;
  transform: translate(14px, 16px) scale(1);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: none;

  [dir=rtl] & {
    right: 0;
    left: unset;
    transform-origin: right top;
    transform: translate(-14px, 16px) scale(1);
    
  }
}

.input-label.pl-8 {
  padding-left: 2rem !important;

  [dir=rtl] & {
    padding-right: 2rem !important;
  }
}

.input-label__focused.pl-8 {
  padding-left: 0;

  [dir=rtl] & {
    padding-right: 0;
  }
}

.input-label.text-red {
  color: red !important;
}

.input-label__focused {
  top: -2px;
  max-width: calc(133% - 24px);
  transform: translate(14px, -9px) scale(0.75);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: auto;
  user-select: none;
  padding-left: 0 !important; 
  color: #000 !important;

  [dir=rtl] & {
    transform: translate(-14px, -9px) scale(0.75);
    padding-right: 0 !important; 
  
  }
}

.input-field,
select {
  border-radius: 4px;
  border-width: 0;
  display: block;
  font-size: .875rem;
  line-height: 1.25rem;
  margin: 0;
  min-width: 18px;
  padding-bottom: 1rem;
  padding-top: 1rem;
  transition-duration: 10ms;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  animation-name: mui-auto-fill-cancel;
  -webkit-background-clip: text;
} 

.text-inherit {
  color: inherit!important;
}

.input-field.pl-12 {
  padding-left: 3rem !important;
}

.input-field:focus, select:focus {
  --tw-text-opacity: 1;
  color: rgb(17 24 39/var(--tw-text-opacity));
  outline: 2px solid transparent;
  outline-offset: 2px;
}

// <ErrorInput />
.error-input {
  color: red;
  font-size: 12px;
  font-weight: 300;
  margin: 6px 0;
  display: flex;
  align-items: center;
}

// <SelectOptions />
.select-overlay {
  border-color: transparent;
  border: 0;
  outline: none;
  text-align: -webkit-left;

  [dir=rtl] & {
    text-align: -webkit-right;
  }
}

.externalWidth {
  width: 170px;
}

.select-overlay .select__control {
  border: 0;
  outline: none;
}

.select-overlay .select__control--is-focused {
  border: none;
  border-color: transparent;
  box-shadow: none;
}

.avatarImage {
padding: 5px 10px;
border-radius: 50px;
background-color: #f4f1f1;
color:black;
font-size: 18px;
margin-right: 12px;
text-transform: capitalize;
display: inline;
align-items: center;
justify-content: center;

[dir=rtl] & {
  margin-left: 12px;
}
}
.profileImage {
margin-right: 12px;
width:20px;
height:20px
}
[dir=rtl] .profileImage {
margin-right: 12px;
width:20px;
height:20px
}

.option-name {
font-size: 12px;
font-family: "IBMPlexSans-SemiBold", sans-serif;
margin: 0;

}
.option-subText {
font-weight: 400;
font-size: 12px;
color: #898A8D;
}
.selected {
display: flex;
align-items: center;
padding: 0;
}


[dir=rtl] .flex-poistion {
flex-direction: row-reverse;
}

.flex-1 { 
  flex: 1;
}

.max-w-80 {
  max-width: 80%;
}

.rounded-icon {
  background-color: transparent;
  border: 1px solid #eee;
  border-radius: 50%;
  border-width: 1px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  width: 2rem;
  height: 2rem;
}

.rounded-icon--bigger {
  width: 3rem;
  height: 3rem;
  // background: red;
}

.notify-nums {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #ddd;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

input[type='checkbox'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: #e8ecef;
  width: 1.15em;
  height: 1.15em;
  border: 2px solid #e8ecef;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type='checkbox']::before {
  content: '';
  width: 0.5em;
  height: 0.5em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #9ca3af;
  background-color: CanvasText;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type='checkbox']:checked::before {
  transform: scale(1);
}

input[type='checkbox']:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

input[type='checkbox']:disabled {
  --form-control-color: #959495;

  color: #959495;
  cursor: not-allowed;
}

.white-space-none {
  white-space: normal;
}

.w-full {
  width: 100%;
}

.bg-white {
  background: white;
}

.bg-black {
  background-color: #000 !important;
}

.rounded {
  border-radius: 0.25rem;
}

.border-b-3 {
  border-bottom: 3px solid #000 !important;
}

.bg-gray-native {
  background: #F4F4F4 !important;
}

.bg-gray {
  background: #F5F8FA;
}

.font-bold {
  font-weight: bold;
}

.w-40 {
  width: 10rem;
}

.w-60 {
  width: 25rem;
}

.max-h-500 {
  height: 500px;
  max-height: 500px;  
  overflow-y: auto;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}

.bg-eee {
  background-color: #f7f7fa;
}

.modal-body {
  padding: 0 !important;
}

.width-30 {
  width: 30%;
}

.width-70 {
  width: 70%;
}

.lead-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #222;
  color: white;
  display: flex;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin: 0 auto 10px auto;
}

.text-right {
  text-align: right;
}

.color-gray {
  color: #A1A5B7;
  font-size: 12px;
}

.color-black {
  color: #333;
}

.bg-bisque {
  background-color: #e6e6fa;
}

.rs-picker-daterange-menu {
  z-index: 10000000000; /* added a large random number, you can choose it more carefully based on your use-case.*/
}

.phone {
  border-radius: 0.375rem;
  border-width: 1px;
  height: 44px;
  overflow: hidden;
  width: 100%;
  border: 1px solid #e5e7eb;

  &.error-input {
    border: 1px solid red;
  }
}

.phone .PhoneInputCountry {
  margin: 0;
  padding-left: 1.25rem;
  width: 4rem;
}

.phone input[type='tel'] {
  font-size: .875rem;
  height: 100%;
  line-height: 1.25rem;
  margin-top: 0;
  width: 100%;
  border: 0;
}

.Toastify__toast-body {
  white-space: pre-line;
}