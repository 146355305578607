.pagination {
    display: flex;
    justify-content: end;
    padding: 10;

    li {
        display: inline-block;
        list-style: none;
        margin: 0 2px;

        &.previous,
        &.next {
          a {
            background: none;
            color: #b5b5c3;
            font-size: 20px;
          }
        }

        a {
            font-weight: 400;
            color: #fff;
            width: 25px;
            height: 25px;
            text-align: center;
            line-height: 30px;
            background: transparent;
            border-radius: 4px;
            font-size: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;


            &:hover {
              background-color: #d6ebf7;
              color: #1DA865 !important;
            }
        }
    }
}

.pagination li.active a {
  background: #1DA865;   
  color: #fff !important;
}